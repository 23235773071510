:root {
  --bgcolor: #371464;
}

body {
  overflow: hidden;
  background-color: #ffde59;
  animation: animatedBackground 1000s linear infinite;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='black' fill-opacity='0.03' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -10000px 0;
  }
}

.katavlaLogo{ 
  display: flex;
  height: 100vh;
  width: 100vw; 
  align-items: center;
  justify-content: center;
  }